import React from 'react'
import Button from 'components/Button'
import Link from 'components/Link'
import PropTypes from 'prop-types'
import { Title42, Title20, Title26, Body14 } from 'components/Typography'
import currencyUtils from 'utils/currency'
import { Center, CtaRow, Empty, Totals, Wrapper } from './ServiceBasket.style'

const ServiceBasket = ({
  checkoutLabel,
  checkoutUrl,
  ctaLabel,
  ctaUrl,
  currency,
  emptyText,
  myServices,
  services,
  totalLabel,
  showCheckoutLinks,
}) => {
  const sum =
    services.length &&
    Array.from(services, ({ total }) => total).reduce((a, b) => a + b)
  return (
    <Wrapper className={services.length ? 'has-items' : 'empty'}>
      {services.length ? (
        <Center>
          <Totals>
            <table>
              <tbody>
                <tr>
                  <Title20 as="th">
                    <Title26>{totalLabel}</Title26>
                  </Title20>
                  <Title20 as="th">
                    <Title26>{currencyUtils[currency](sum)}</Title26>
                  </Title20>
                </tr>
                {services.map((service) => (
                  <tr key={service.label}>
                    <td>{service.label}</td>
                    <td>{currencyUtils[currency](service.total)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Totals>
          {showCheckoutLinks && (
            <CtaRow>
              <Button className="checkout" as={Link} to={checkoutUrl}>
                {checkoutLabel}
              </Button>
              <Button className="cta" as={Link} to={ctaUrl}>
                {ctaLabel}
              </Button>
            </CtaRow>
          )}
        </Center>
      ) : (
        <Empty>
          <Title42 as="h2">{myServices}</Title42>
          <Body14>{emptyText}</Body14>
        </Empty>
      )}
    </Wrapper>
  )
}

ServiceBasket.propTypes = {
  checkoutLabel: PropTypes.string,
  checkoutUrl: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  currency: PropTypes.string,
  emptyText: PropTypes.string,
  myServices: PropTypes.string,
  services: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  totalLabel: PropTypes.string,
  showCheckoutLinks: PropTypes.bool,
}

ServiceBasket.defaultProps = {
  checkoutLabel: '',
  checkoutUrl: '',
  ctaLabel: '',
  ctaUrl: '',
  currency: 'GBP',
  emptyText: '',
  myServices: '',
  services: [],
  totalLabel: '',
  showCheckoutLinks: true,
}

export default ServiceBasket
