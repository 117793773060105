import React from 'react'
import Button from 'components/Button'
import Link from 'components/Link'
import PropTypes from 'prop-types'
import MarkdownRenderer from 'components/MarkdownRenderer'
import trackGTM from 'utils/trackGTM'
import GA4 from 'utils/GA4'
import { useComponentId, useFieldId } from 'utils/optimizely'
import { Eyebrow, Title32, Body16 } from 'components/Typography'
import { Spacing10, Spacing30, Spacing20, Spacing50 } from 'styles/sharedStyle'
import { fixAudiences } from '../../utils/audiences'
import { Center, CtaRow, Body, Wrapper } from './CtaHeading.style'

const CtaHeading = ({
  audiences,
  body,
  bodyCopy,
  cta1Label,
  cta1Url,
  cta2Label,
  cta2Url,
  eyebrow,
  headline,
  villageName,
}) => {
  const ctas = [
    cta1Label && {
      label: cta1Label,
      to: cta1Url,
    },
    cta2Label && {
      label: cta2Label,
      to: cta2Url,
    },
  ].filter((o) => !!o)

  const hasEyebrow = !!eyebrow
  const hasHeadline = !!headline
  const hasContent = !!(body || bodyCopy)
  const hasCTA = ctas.length > 0
  const hasAudiences = !!audiences
  const elementId = useComponentId('Comp_block_text_cta_BLK01')

  return (
    <Wrapper
      hasAudience={hasAudiences}
      id={elementId}
      className={fixAudiences(audiences)}>
      <Center>
        <Spacing50 />
        <>
          {hasEyebrow && (
            <>
              <Eyebrow as="span" id={useFieldId(elementId, `eyebrow`)}>
                {eyebrow}
              </Eyebrow>
              {(hasHeadline || hasContent || hasCTA) && <Spacing10 />}
            </>
          )}
          {hasHeadline && (
            <>
              <Title32 id={useFieldId(elementId, `title`)}>{headline}</Title32>
              {(hasContent || hasCTA) && <Spacing30 />}
            </>
          )}
          {hasContent && (
            <>
              <Body16 as="div">
                <Body
                  as={MarkdownRenderer}
                  id={useFieldId(elementId, `body`)}
                  html={body || `<p>${bodyCopy}</p>`}
                />
              </Body16>
              {hasCTA && <Spacing20 />}
            </>
          )}
          {hasCTA && (
            <CtaRow>
              {ctas.map((cta, i) => (
                <Button
                  id={useFieldId(elementId, `cta-${i + 1}`)}
                  innerId={useFieldId(elementId, `cta-${i + 1}--text`)}
                  className="cta"
                  onClick={() => {
                    trackGTM('CTA', 'cta click', 'in page cta', cta.label)
                    GA4('block_text_cta_click', {
                      component_headline: headline,
                      headline: cta.label,
                      village_name: villageName,
                    })
                  }}
                  external={
                    cta.to &&
                    (cta.to.indexOf('http:') === 0 ||
                      cta.to.indexOf('https:') === 0)
                  }
                  as={cta.to && Link}
                  level={i < 1 ? 'secondary' : 'primary'}
                  {...cta}
                  key={cta.label}>
                  {cta.label}
                </Button>
              ))}
            </CtaRow>
          )}
        </>
        <Spacing50 />
      </Center>
    </Wrapper>
  )
}

CtaHeading.propTypes = {
  audiences: PropTypes.string,
  body: PropTypes.string,
  bodyCopy: PropTypes.string,
  cta1Label: PropTypes.string,
  cta1Url: PropTypes.string,
  cta2Label: PropTypes.string,
  cta2Url: PropTypes.string,
  eyebrow: PropTypes.string,
  headline: PropTypes.string,
  villageName: PropTypes.string,
}

CtaHeading.defaultProps = {
  audiences: '',
  body: '',
  bodyCopy: '',
  cta1Label: '',
  cta1Url: '',
  cta2Label: '',
  cta2Url: '',
  eyebrow: '',
  headline: '',
  villageName: '',
}

export default CtaHeading
