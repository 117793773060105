import { COUNTRIES_PHONE_PREFIX } from 'constants/index'

function centerField(element) {
  if (element && element.offsetParent) {
    const bodyRect = document.body.getBoundingClientRect()
    const elemRect = element.getBoundingClientRect()
    const offset = elemRect.top - bodyRect.top

    window.scrollTo(0, offset - window.innerHeight / 2)
  }
}

export const navigateFirstInvalid = () => {
  const firstErrorMessage = document.querySelector('.error')
  if (firstErrorMessage) {
    centerField(firstErrorMessage)
  }
}

export const validations = () => {
  const mobilePrefixOptions = COUNTRIES_PHONE_PREFIX.map(
    (prefix) => `${prefix.dial_code}`
  )

  const countries = COUNTRIES_PHONE_PREFIX.map((prefix) => `${prefix.text}`)

  const notNull = (value) => value

  const stringNotNullOrEmpty = (value) => notNull(value.trim())

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return stringNotNullOrEmpty(email) && re.test(email)
  }

  const validateEmailNotRequired = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !stringNotNullOrEmpty(email) || re.test(email)
  }

  const lengthIsBetween = (value, min, max) => {
    if (min > max) {
      throw new Error('Max is greater than min.')
    }

    return value.length >= min && value.length <= max
  }

  const stringContainsOnlyDigits = (value) => {
    const regex = /^[0-9]*$/
    return regex.test(value)
  }

  const validateBookingReference = (value) =>
    stringNotNullOrEmpty(value) &&
    stringContainsOnlyDigits(value) &&
    lengthIsBetween(value, 6, 6)

  const atLeast2Chars = (value) => value && value.length > 1

  const isValidPrefix = (value) => mobilePrefixOptions.includes(value)

  const isValidCountry = (value) => countries.includes(value)

  function isValidateUSZipCode(value) {
    // Regular expression pattern for US zip codes
    const valueRegex = /^\d{5}(?:[-\s]\d{4})?$/
    return valueRegex.test(value)
  }

  return {
    atLeast2Chars,
    isValidCountry,
    isValidPrefix,
    lengthIsBetween,
    notNull,
    stringContainsOnlyDigits,
    stringNotNullOrEmpty,
    validateBookingReference,
    validateEmail,
    validateEmailNotRequired,
    isValidateUSZipCode,
  }
}

export const prefixMask = (value) => value.match(/\((.*)\)/)[1]

export default { navigateFirstInvalid, validations, prefixMask }
