import styled from 'styled-components'
import theme from 'styles/theme'
import { rem } from 'polished'

export const Wrapper = styled.div`
  background: ${theme.colors.sagedark};
  color: ${theme.colors.white};

  &.has-items {
    padding: 0 20px 31px;

    ${theme.mediaQueries.medium} {
      padding: 0 20px 40px;
    }
  }
`

export const Center = styled.div`
  margin: 0 auto;
  max-width: 315px;

  ${theme.mediaQueries.medium} {
    max-width: 580px;
  }
`

export const Totals = styled.div`
  display: flex;
  flex-direction: column;
  padding: 11px 0 12px;

  ${theme.mediaQueries.medium} {
    padding: 23px 0 10px;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 -10px;
    text-align: left;
    width: auto;

    ${theme.mediaQueries.medium} {
      margin: 0 -20px;
    }
  }

  th,
  td {
    padding: 20px 10px;

    ${theme.mediaQueries.medium} {
      width: 50%;
    }

    &:first-child {
      padding-left: 10px;

      ${theme.mediaQueries.medium} {
        padding-left: 20px;
      }
    }

    &:last-child {
      padding-right: 10px;

      ${theme.mediaQueries.medium} {
        padding-right: 20px;
      }
    }
  }

  th {
    ${theme.mediaQueries.medium} {
      padding-bottom: 21px;
    }
  }

  td {
    // theme.colors.sagemedium
    border-top: 1px solid rgba(133, 147, 149, 0.3);
    padding-top: 24px;

    ${theme.mediaQueries.medium} {
      padding-bottom: 23px;
    }

    &:last-child {
      ${theme.mediaQueries.medium} {
        color: ${theme.colors.sagemedium};
      }
    }
  }
`

export const CtaRow = styled.div`
  ${theme.mediaQueries.medium} {
    display: flex;
  }

  a {
    border-color: ${theme.colors.white};
    display: block;
    flex: 1 1 50%;
    margin: 0 0 11px;
    padding: ${rem(20)};

    ${theme.mediaQueries.medium} {
      margin: 0 10px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  .checkout {
    &:hover:not(:disabled) {
      border-color: ${theme.colors.sagemedium};
    }
  }

  .cta {
    border-color: ${theme.colors.sagemedium};
    background: ${theme.colors.sagedark};
    color: ${theme.colors.white};

    &:hover:not(:disabled) {
      border-color: ${theme.colors.white};
      background: ${theme.colors.white};
      color: ${theme.colors.sagedark};
    }
  }
`

export const Empty = styled.div`
  padding: 45px 0 41px;
  text-align: center;

  ${theme.mediaQueries.medium} {
    padding: 53px 0 51px;
  }

  h2 {
    margin: 0 0 15px;

    ${theme.mediaQueries.medium} {
      margin-bottom: 21px;
    }
  }

  p {
    color: ${theme.colors.sagemedium};
    margin: 0;
  }
`
