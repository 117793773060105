export const OPEN_STATUS = 'Open'
export const OPENING_STATUS = 'Opening soon'
export const CLOSED_STATUS = 'Closed'
export const CLOSING_STATUS = 'Closing soon'
export const REOPENING_STATUS = 'Reopening soon'

export default {
  OPEN_STATUS,
  OPENING_STATUS,
  CLOSED_STATUS,
  CLOSING_STATUS,
  REOPENING_STATUS,
}
