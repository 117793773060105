import styled from 'styled-components'
import { themeGet } from 'styled-system'
import theme from 'styles/theme'
import { FormInput } from 'components/Typography'

export const InputText = styled(FormInput).attrs((props) => ({
  disabled: props.isDisabled,
}))`
  border: 1px solid transparent;
  outline: none;
  transition: border 0.3s ease-in-out;
  width: 100%;
  padding: ${theme.space[2]};
  color: ${themeGet('colors.sagedark')};
  border-color: ${(props) =>
    !props.hasError ? theme.colors.sagelight : themeGet('colors.red')};

  &:focus {
    border-color: ${(props) =>
      !props.hasError ? theme.colors.sagemedium : themeGet('colors.red')};
  }
`

export const Wrapper = styled.div`
  .suggestions {
    border: 1px solid transparent;
    outline: none;
    transition: border 0.3s ease-in-out;
    width: 100%;
    color: ${themeGet('colors.sagedark')};
    border-color: ${themeGet('colors.sagelight')};
    box-shadow: 0;
    border-radius: 0;
    border-top-width: 0;
    list-style: none;
    margin-top: -1.5rem;
    max-height: 165px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(300px + 1rem);
    background-color: ${themeGet('colors.white')};
    position: absolute;
    z-index: 10;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .suggestions li {
    display: inline-block;
    width: 100%;
  }

  .suggestion-button {
    border: 1px solid transparent;
    background-color: transparent;
    background-image: none;
  }

  .suggestions-active,
  .suggestions li:hover {
    background-color: ${themeGet('colors.softblue')};
    color: ${themeGet('colors.white')};
    cursor: pointer;
  }

  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid transparent;
  }
`

export const ListItem = styled.div`
  padding: 0.3rem;
  padding-top: 0rem;
  &:hover {
    background-color: ${themeGet('color.softblue')};
    cursor: pointer;
  }
`
