import KEY_CODES from './keyCodes'

import {
  OPEN_STATUS,
  OPENING_STATUS,
  CLOSED_STATUS,
  CLOSING_STATUS,
  REOPENING_STATUS,
} from './openStatus'

export const OPACITIES = {
  '0%': 0,
  '15%': 0.15,
  '30%': 0.3,
}

export const COUNTRIES_PHONE_PREFIX = [
  { value: 'AF', text: 'Afghanistan', dial_code: '+93' },
  { value: 'AX', text: 'Aland Islands', dial_code: '+358' },
  { value: 'AL', text: 'Albania', dial_code: '+355' },
  { value: 'DZ', text: 'Algeria', dial_code: '+213' },
  { value: 'AS', text: 'American Samoa', dial_code: '+1684' },
  { value: 'AD', text: 'Andorra', dial_code: '+376' },
  { value: 'AO', text: 'Angola', dial_code: '+244' },
  { value: 'AI', text: 'Anguilla', dial_code: '+1264' },
  { value: 'AQ', text: 'Antarctica', dial_code: '+672' },
  { value: 'AG', text: 'Antigua and Barbuda', dial_code: '+1268' },
  { value: 'AR', text: 'Argentina', dial_code: '+54' },
  { value: 'AM', text: 'Armenia', dial_code: '+374' },
  { value: 'AW', text: 'Aruba', dial_code: '+297' },
  { value: 'AU', text: 'Australia', dial_code: '+61' },
  { value: 'AT', text: 'Austria', dial_code: '+43' },
  { value: 'AZ', text: 'Azerbaijan', dial_code: '+994' },
  { value: 'BS', text: 'Bahamas', dial_code: '+1242' },
  { value: 'BH', text: 'Bahrain', dial_code: '+973' },
  { value: 'BD', text: 'Bangladesh', dial_code: '+880' },
  { value: 'BB', text: 'Barbados', dial_code: '+1246' },
  { value: 'BY', text: 'Belarus', dial_code: '+375' },
  { value: 'BE', text: 'Belgium', dial_code: '+32' },
  { value: 'BZ', text: 'Belize', dial_code: '+501' },
  { value: 'BJ', text: 'Benin', dial_code: '+229' },
  { value: 'BM', text: 'Bermuda', dial_code: '+1441' },
  { value: 'BT', text: 'Bhutan', dial_code: '+975' },
  { value: 'BO', text: 'Bolivia', dial_code: '+591' },
  { value: 'BQ', text: 'Bonaire', dial_code: '+599' },
  { value: 'BA', text: 'Bosnia and Herzegovina', dial_code: '+387' },
  { value: 'BW', text: 'Botswana', dial_code: '+267' },
  { value: 'BV', text: 'Bouvet Island', dial_code: '+47' },
  { value: 'BR', text: 'Brazil', dial_code: '+55' },
  { value: 'IO', text: 'British Indian Ocean Territory', dial_code: '+246' },
  { value: 'BN', text: 'Brunei Darussalam', dial_code: '+673' },
  { value: 'BG', text: 'Bulgaria', dial_code: '+359' },
  { value: 'BF', text: 'Burkina Faso', dial_code: '+226' },
  { value: 'BI', text: 'Burundi', dial_code: '+257' },
  { value: 'KH', text: 'Cambodia', dial_code: '+855' },
  { value: 'CM', text: 'Cameroon', dial_code: '+237' },
  { value: 'CA', text: 'Canada', dial_code: '+1' },
  { value: 'CV', text: 'Cape Verde', dial_code: '+238' },
  { value: 'KY', text: 'Cayman Islands', dial_code: '+345' },
  { value: 'CF', text: 'Central African Republic', dial_code: '+236' },
  { value: 'TD', text: 'Chad', dial_code: '+235' },
  { value: 'CL', text: 'Chile', dial_code: '+56' },
  { value: 'CN', text: 'China', dial_code: '+86' },
  { value: 'CX', text: 'Christmas Island', dial_code: '+61' },
  { value: 'CC', text: 'Cocos (Keeling) Islands', dial_code: '+61' },
  { value: 'CO', text: 'Colombia', dial_code: '+57' },
  { value: 'KM', text: 'Comoros', dial_code: '+269' },
  { value: 'CG', text: 'Congo', dial_code: '+242' },
  { value: 'CD', text: 'Congo', dial_code: '+243' },
  { value: 'CK', text: 'Cook Islands', dial_code: '+682' },
  { value: 'CR', text: 'Costa Rica', dial_code: '+506' },
  { value: 'CI', text: `Cote D'Ivoire`, dial_code: '+225' },
  { value: 'HR', text: 'Croatia', dial_code: '+385' },
  { value: 'CU', text: 'Cuba', dial_code: '+53' },
  { value: 'CW', text: 'Curacao', dial_code: '+599' },
  { value: 'CY', text: 'Cyprus', dial_code: '+357' },
  { value: 'CZ', text: 'Czech Republic', dial_code: '+420' },
  { value: 'DK', text: 'Denmark', dial_code: '+45' },
  { value: 'DJ', text: 'Djibouti', dial_code: '+253' },
  { value: 'DM', text: 'Dominica', dial_code: '+1767' },
  { value: 'DO', text: 'Dominican Republic', dial_code: '+1849' },
  { value: 'EC', text: 'Ecuador', dial_code: '+593' },
  { value: 'EG', text: 'Egypt', dial_code: '+20' },
  { value: 'SV', text: 'El Salvador', dial_code: '+503' },
  { value: 'GQ', text: 'Equatorial Guinea', dial_code: '+240' },
  { value: 'ER', text: 'Eritrea', dial_code: '+291' },
  { value: 'EE', text: 'Estonia', dial_code: '+372' },
  { value: 'ET', text: 'Ethiopia', dial_code: '+251' },
  { value: 'FK', text: 'Falkland Islands (Malvinas)', dial_code: '+500' },
  { value: 'FO', text: 'Faroe Islands', dial_code: '+298' },
  { value: 'FJ', text: 'Fiji', dial_code: '+679' },
  { value: 'FI', text: 'Finland', dial_code: '+358' },
  { value: 'FR', text: 'France', dial_code: '+33' },
  { value: 'GF', text: 'French Guiana', dial_code: '+594' },
  { value: 'PF', text: 'French Polynesia', dial_code: '+689' },
  { value: 'TF', text: 'French Southern Territories', dial_code: '+262' },
  { value: 'GA', text: 'Gabon', dial_code: '+241' },
  { value: 'GM', text: 'Gambia', dial_code: '+220' },
  { value: 'GE', text: 'Georgia', dial_code: '+995' },
  { value: 'DE', text: 'Germany', dial_code: '+49' },
  { value: 'GH', text: 'Ghana', dial_code: '+233' },
  { value: 'GI', text: 'Gibraltar', dial_code: '+350' },
  { value: 'GR', text: 'Greece', dial_code: '+30' },
  { value: 'GL', text: 'Greenland', dial_code: '+299' },
  { value: 'GD', text: 'Grenada', dial_code: '+1473' },
  { value: 'GP', text: 'Guadeloupe', dial_code: '+590' },
  { value: 'GU', text: 'Guam', dial_code: '+1671' },
  { value: 'GT', text: 'Guatemala', dial_code: '+502' },
  { value: 'GG', text: 'Guernsey', dial_code: '+44' },
  { value: 'GN', text: 'Guinea', dial_code: '+224' },
  { value: 'GW', text: 'Guinea-Bissau', dial_code: '+245' },
  { value: 'GY', text: 'Guyana', dial_code: '+595' },
  { value: 'HT', text: 'Haiti', dial_code: '+509' },
  { value: 'HM', text: 'Heard Island and Mcdonald Islands', dial_code: '+672' },
  { value: 'VA', text: 'Holy See Vatican City State', dial_code: '+379' },
  { value: 'HN', text: 'Honduras', dial_code: '+504' },
  { value: 'HK', text: 'Hong Kong', dial_code: '+852' },
  { value: 'HU', text: 'Hungary', dial_code: '+36' },
  { value: 'IS', text: 'Iceland', dial_code: '+354' },
  { value: 'IN', text: 'India', dial_code: '+91' },
  { value: 'ID', text: 'Indonesia', dial_code: '+62' },
  { value: 'IR', text: 'Iran', dial_code: '+98' },
  { value: 'IQ', text: 'Iraq', dial_code: '+964' },
  { value: 'IE', text: 'Ireland', dial_code: '+353' },
  { value: 'IM', text: 'Isle of Man', dial_code: '+44' },
  { value: 'IL', text: 'Israel', dial_code: '+972' },
  { value: 'IT', text: 'Italy', dial_code: '+39' },
  { value: 'JM', text: 'Jamaica', dial_code: '+1876' },
  { value: 'JP', text: 'Japan', dial_code: '+81' },
  { value: 'JE', text: 'Jersey', dial_code: '+44' },
  { value: 'JO', text: 'Jordan', dial_code: '+962' },
  { value: 'KZ', text: 'Kazakhstan', dial_code: '+77' },
  { value: 'KE', text: 'Kenya', dial_code: '+254' },
  { value: 'KI', text: 'Kiribati', dial_code: '+686' },
  { value: 'KP', text: 'Korea', dial_code: '+850' },
  { value: 'KR', text: 'Korea', dial_code: '+82' },
  { value: 'KW', text: 'Kuwait', dial_code: '+965' },
  { value: 'KG', text: 'Kyrgyzstan', dial_code: '+996' },
  { value: 'LA', text: `Lao People's Democratic Republic`, dial_code: '+856' },
  { value: 'LV', text: 'Latvia', dial_code: '+371' },
  { value: 'LB', text: 'Lebanon', dial_code: '+961' },
  { value: 'LS', text: 'Lesotho', dial_code: '+266' },
  { value: 'LR', text: 'Liberia', dial_code: '+231' },
  { value: 'LY', text: 'Libyan Arab Jamahiriya', dial_code: '+218' },
  { value: 'LI', text: 'Liechtenstein', dial_code: '+423' },
  { value: 'LT', text: 'Lithuania', dial_code: '+370' },
  { value: 'LU', text: 'Luxembourg', dial_code: '+352' },
  { value: 'MO', text: 'Macao', dial_code: '+853' },
  { value: 'MK', text: 'Macedonia', dial_code: '+389' },
  { value: 'MG', text: 'Madagascar', dial_code: '+261' },
  { value: 'MW', text: 'Malawi', dial_code: '+265' },
  { value: 'MY', text: 'Malaysia', dial_code: '+60' },
  { value: 'MV', text: 'Maldives', dial_code: '+960' },
  { value: 'ML', text: 'Mali', dial_code: '+223' },
  { value: 'MT', text: 'Malta', dial_code: '+356' },
  { value: 'MH', text: 'Marshall Islands', dial_code: '+692' },
  { value: 'MQ', text: 'Martinique', dial_code: '+596' },
  { value: 'MR', text: 'Mauritania', dial_code: '+222' },
  { value: 'MU', text: 'Mauritius', dial_code: '+230' },
  { value: 'YT', text: 'Mayotte', dial_code: '+262' },
  { value: 'MX', text: 'Mexico', dial_code: '+52' },
  { value: 'FM', text: 'Micronesia', dial_code: '+691' },
  { value: 'MD', text: 'Moldova', dial_code: '+373' },
  { value: 'MC', text: 'Monaco', dial_code: '+377' },
  { value: 'MN', text: 'Mongolia', dial_code: '+976' },
  { value: 'ME', text: 'Montenegro', dial_code: '+382' },
  { value: 'MS', text: 'Montserrat', dial_code: '+1664' },
  { value: 'MA', text: 'Morocco', dial_code: '+212' },
  { value: 'MZ', text: 'Mozambique', dial_code: '+258' },
  { value: 'MM', text: 'Myanmar', dial_code: '+95' },
  { value: 'NA', text: 'Namibia', dial_code: '+264' },
  { value: 'NR', text: 'Nauru', dial_code: '+674' },
  { value: 'NP', text: 'Nepal', dial_code: '+977' },
  { value: 'NL', text: 'Netherlands', dial_code: '+31' },
  { value: 'NC', text: 'New Caledonia', dial_code: '+687' },
  { value: 'NZ', text: 'New Zealand', dial_code: '+64' },
  { value: 'NI', text: 'Nicaragua', dial_code: '+505' },
  { value: 'NE', text: 'Niger', dial_code: '+227' },
  { value: 'NG', text: 'Nigeria', dial_code: '+234' },
  { value: 'NU', text: 'Niue', dial_code: '+683' },
  { value: 'NF', text: 'Norfolk Island', dial_code: '+672' },
  { value: 'MP', text: 'Northern Mariana Islands', dial_code: '+1670' },
  { value: 'NO', text: 'Norway', dial_code: '+47' },
  { value: 'OM', text: 'Oman', dial_code: '+968' },
  { value: 'PK', text: 'Pakistan', dial_code: '+92' },
  { value: 'PW', text: 'Palau', dial_code: '+680' },
  { value: 'PS', text: 'Palestinian Territory', dial_code: '+970' },
  { value: 'PA', text: 'Panama', dial_code: '+507' },
  { value: 'PG', text: 'Papua New Guinea', dial_code: '+675' },
  { value: 'PY', text: 'Paraguay', dial_code: '+595' },
  { value: 'PE', text: 'Peru', dial_code: '+51' },
  { value: 'PH', text: 'Philippines', dial_code: '+63' },
  { value: 'PN', text: 'Pitcairn', dial_code: '+872' },
  { value: 'PL', text: 'Poland', dial_code: '+48' },
  { value: 'PT', text: 'Portugal', dial_code: '+351' },
  { value: 'PR', text: 'Puerto Rico', dial_code: '+1939' },
  { value: 'QA', text: 'Qatar', dial_code: '+974' },
  { value: 'RE', text: 'Reunion', dial_code: '+262' },
  { value: 'RO', text: 'Romania', dial_code: '+40' },
  { value: 'RU', text: 'Russian Federation', dial_code: '+7' },
  { value: 'RW', text: 'Rwanda', dial_code: '+250' },
  { value: 'BL', text: 'Saint Barthelemy', dial_code: '+590' },
  { value: 'SH', text: 'Saint Helena', dial_code: '+290' },
  { value: 'KN', text: 'Saint Kitts and Nevis', dial_code: '+1869' },
  { value: 'LC', text: 'Saint Lucia', dial_code: '+1758' },
  { value: 'MF', text: 'Saint Martin (French Part)', dial_code: '+590' },
  { value: 'PM', text: 'Saint Pierre and Miquelon', dial_code: '+508' },
  { value: 'VC', text: 'Saint Vincent and the Grenadines', dial_code: '+1784' },
  { value: 'WS', text: 'Samoa', dial_code: '+685' },
  { value: 'SM', text: 'San Marino', dial_code: '+378' },
  { value: 'ST', text: 'Sao Tome and Principe', dial_code: '+239' },
  { value: 'SA', text: 'Saudi Arabia', dial_code: '+966' },
  { value: 'SN', text: 'Senegal', dial_code: '+221' },
  { value: 'RS', text: 'Serbia', dial_code: '+381' },
  { value: 'SC', text: 'Seychelles', dial_code: '+248' },
  { value: 'SL', text: 'Sierra Leone', dial_code: '+232' },
  { value: 'SG', text: 'Singapore', dial_code: '+65' },
  { value: 'SX', text: 'Sint Maarten (Dutch Part)', dial_code: '+1' },
  { value: 'SK', text: 'Slovakia', dial_code: '+421' },
  { value: 'SI', text: 'Slovenia', dial_code: '+386' },
  { value: 'SB', text: 'Solomon Islands', dial_code: '+677' },
  { value: 'SO', text: 'Somalia', dial_code: '+252' },
  { value: 'ZA', text: 'South Africa', dial_code: '+27' },
  {
    value: 'GS',
    text: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
  },
  { value: 'ES', text: 'Spain', dial_code: '+34' },
  { value: 'LK', text: 'Sri Lanka', dial_code: '+94' },
  { value: 'SD', text: 'Sudan', dial_code: '+249' },
  { value: 'SR', text: 'Suriname', dial_code: '+597' },
  { value: 'SJ', text: 'Svalbard and Jan Mayen', dial_code: '+47' },
  { value: 'SZ', text: 'Swaziland', dial_code: '+268' },
  { value: 'SE', text: 'Sweden', dial_code: '+46' },
  { value: 'CH', text: 'Switzerland', dial_code: '+41' },
  { value: 'SY', text: 'Syrian Arab Republic', dial_code: '+963' },
  { value: 'TW', text: 'Taiwan', dial_code: '+886' },
  { value: 'TJ', text: 'Tajikistan', dial_code: '+992' },
  { value: 'TZ', text: 'Tanzania', dial_code: '+255' },
  { value: 'TH', text: 'Thailand', dial_code: '+66' },
  { value: 'TL', text: 'Timor-Leste', dial_code: '+670' },
  { value: 'TG', text: 'Togo', dial_code: '+228' },
  { value: 'TK', text: 'Tokelau', dial_code: '+690' },
  { value: 'TO', text: 'Tonga', dial_code: '+676' },
  { value: 'TT', text: 'Trinidad and Tobago', dial_code: '+1868' },
  { value: 'TN', text: 'Tunisia', dial_code: '+216' },
  { value: 'TR', text: 'Turkey', dial_code: '+90' },
  { value: 'TM', text: 'Turkmenistan', dial_code: '+993' },
  { value: 'TC', text: 'Turks and Caicos Islands', dial_code: '+1649' },
  { value: 'TV', text: 'Tuvalu', dial_code: '+688' },
  { value: 'UG', text: 'Uganda', dial_code: '+256' },
  { value: 'UA', text: 'Ukraine', dial_code: '+380' },
  { value: 'AE', text: 'United Arab Emirates', dial_code: '+971' },
  { value: 'GB', text: 'United Kingdom', dial_code: '+44' },
  { value: 'US', text: 'United States', dial_code: '+1' },
  {
    value: 'UM',
    text: 'United States Minor Outlying Islands',
    dial_code: '+1',
  },
  { value: 'UY', text: 'Uruguay', dial_code: '+598' },
  { value: 'UZ', text: 'Uzbekistan', dial_code: '+998' },
  { value: 'VU', text: 'Vanuatu', dial_code: '+678' },
  {
    value: 'VATICAN CITY STATE',
    text: 'Vatican City State',
    dial_code: '+379',
  },
  { value: 'VE', text: 'Venezuela', dial_code: '+58' },
  { value: 'VN', text: 'Vietnam', dial_code: '+84' },
  { value: 'VG', text: 'Virgin Islands', dial_code: '+1284' },
  { value: 'VI', text: 'Virgin Islands', dial_code: '+1340' },
  { value: 'WF', text: 'Wallis and Futuna', dial_code: '+681' },
  { value: 'EH', text: 'Western Sahara', dial_code: '+212' },
  { value: 'YE', text: 'Yemen', dial_code: '+967' },
  { value: 'ZM', text: 'Zambia', dial_code: '+260' },
  { value: 'ZW', text: 'Zimbabwe', dial_code: '+263' },
]

export default {
  OPEN_STATUS,
  OPENING_STATUS,
  CLOSED_STATUS,
  CLOSING_STATUS,
  REOPENING_STATUS,
  COUNTRIES_PHONE_PREFIX,
  KEY_CODES,
  OPACITIES,
}
