import styled from 'styled-components'
import theme from 'styles/theme'
import { rem } from 'polished'

export const Wrapper = styled.div`
  color: ${theme.colors.sagedark};
  padding: 0 20px;
  text-align: center;
`

export const Center = styled.div`
  margin: 0 auto;
  max-width: 780px;
  text-align: center;
`

export const Body = styled.div`
  color: ${theme.colors.sagemedium};
  margin: 0 auto;
  text-align: center;
  width: 265px;

  ${theme.mediaQueries.large} {
    width: auto;
  }

  p {
    font-size: inherit;
  }
`

export const CtaRow = styled.div`
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 100%;

  ${theme.mediaQueries.large} {
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  .cta {
    cursor: pointer;
    display: block;
    margin: 10px 0;
    padding: ${rem(20)};
    width: 100%;

    ${theme.mediaQueries.large} {
      margin: 0 10px;
      width: 280px;
    }
  }
`

export default {
  Wrapper,
}
