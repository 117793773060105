import React, { useState, Fragment } from 'react'
import { FormText } from 'components/Forms'
import PropTypes from 'prop-types'
import KEYCODE from 'constants/keyCodes'
import FormLabel from '../Forms/FormLabel'
import { Wrapper, ListItem } from './Autocomplete.style'

const Autocomplete = ({
  suggestions,
  hasError,
  isDisabled,
  label,
  errorMessage,
  name,
  parentCallback,
  mask,
}) => {
  const [activeSuggestion, setActiveSuggestion] = useState(0)
  const [filteredSuggestions, setFilteredSuggestions] = useState([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [inputs, setInputs] = useState('')

  const clearField = () => {
    setInputs('')
    parentCallback('')
  }

  const onChange = (event) => {
    event.persist()

    const userValue = event.target.value

    const allFilteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion
          .toString()
          .toLowerCase()
          .indexOf(userValue.toString().toLowerCase()) > -1
    )

    setFilteredSuggestions(allFilteredSuggestions)

    setActiveSuggestion(0)
    setShowSuggestions(true)
    setInputs(event.target.value)
    parentCallback(event.target.value)
  }

  const onClick = (event) => {
    event.preventDefault()
    setActiveSuggestion(0)
    setFilteredSuggestions([])
    setShowSuggestions(false)
    const selectedOption = mask(event.target.innerText)
    setInputs(selectedOption)
    parentCallback(selectedOption)
  }

  const onKeyDown = (e) => {
    const selectedOption = filteredSuggestions[activeSuggestion]
    let result = ''
    if (selectedOption !== undefined) {
      result = mask(selectedOption)
    }
    if (e.keyCode === KEYCODE.ENTER) {
      e.preventDefault()
      setActiveSuggestion(0)
      setShowSuggestions(false)
      setInputs(result)
      parentCallback(result)
    }

    if (e.keyCode === KEYCODE.ARROW_UP) {
      if (activeSuggestion === 0 || activeSuggestion === undefined) {
        return
      }
      setActiveSuggestion(activeSuggestion - 1)
    }

    if (e.keyCode === KEYCODE.ARROW_DOWN) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return
      }
      setActiveSuggestion(activeSuggestion + 1)
    }

    if (e.keyCode === KEYCODE.ESCAPE) {
      clearField()
    }
  }
  let suggestionsListComponent = ''

  if (showSuggestions && inputs) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <Wrapper>
          <ul className="suggestions" id="suggestionList">
            {filteredSuggestions.map((suggestion, index) => {
              let className = ''

              if (index === activeSuggestion) {
                className = 'suggestions-active'
              }

              return (
                <ListItem key={suggestion}>
                  <div>
                    <li className={className} key={suggestion}>
                      <button
                        type="button"
                        className="suggestion-button"
                        onClick={onClick}>
                        {' '}
                        {suggestion}
                      </button>
                    </li>
                  </div>
                </ListItem>
              )
            })}
          </ul>
        </Wrapper>
      )
    } else {
      suggestionsListComponent = (
        <div className="no-suggestions">{clearField()}</div>
      )
    }
  }

  return (
    <Fragment>
      <FormLabel hasError={hasError} isDisabled={isDisabled}>
        <FormText
          type="text"
          id={name}
          name={name}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={inputs}
          autoComplete="off"
          label={label}
          handleChange={onChange}
          errorMessage={errorMessage}
          hasError={hasError}
        />
        {suggestionsListComponent}
      </FormLabel>
    </Fragment>
  )
}

Autocomplete.propTypes = {
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  name: PropTypes.string.isRequired,
  suggestions: PropTypes.instanceOf(Array).isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  parentCallback: PropTypes.func,
  mask: PropTypes.func,
}

Autocomplete.defaultProps = {
  hasError: false,
  errorMessage: '',
  isDisabled: false,
  label: '',
  parentCallback: () => {},
  mask: (value) => value,
}

export default Autocomplete
