/*
 * Split a number into comma separated value with
 * exactly 2 decimal places
 *
 * @param num Number
 * @param options Object
 * return String
 */
function punctuate(num, options) {
  const opts = Object.assign(
    {
      decimal: '.',
      mille: ',',
      simple: false,
    },
    options
  )
  const { decimal, mille, simple } = opts
  const reg = `\\d(?=(\\d{3})+\\${decimal})`
  const replacement = `$&${mille}`
  let formatted = Number(num.toString().replace(',', ''))
    .toFixed(2)
    .replace('.', decimal)
    .replace(new RegExp(reg, 'g'), replacement)

  if (simple) {
    formatted = formatted.replace(/[,.]00$/g, '')
  }

  return formatted
}

/*
 * Format a number as EUR currency
 *
 * @param num Number
 * @param options Object
 * return String
 */
function EUR(num, options) {
  const opts = Object.assign(
    {
      decimal: ',',
      mille: '.',
    },
    options
  )
  const formatted = punctuate(num, opts)
  return `${formatted} €`
}

/*
 * Format a number as GBP currency
 *
 * @param num Number
 * @param options Object
 * return String
 */
function GBP(num, options) {
  const formatted = punctuate(num, options)
  return `£${formatted}`
}

/*
 * Format a number as USD currency
 *
 * @param num Number
 * @param options Object
 * return String
 */
function USD(num, options) {
  const formatted = punctuate(num, options)
  return `$${formatted}`
}

export default {
  punctuate,
  EUR,
  GBP,
  USD,
}
